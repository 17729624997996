<template>
  <div id="index" class="edit">
    <div class="line-w">
      <div class="title-w">工程概述</div>
      <div class="text-w">
        <el-input
          v-model="ptext.Content"
          placeholder="请输入工程概述内容"
          type="textarea"
          :rows="6"
          maxlength="1000"
          :readonly="false"
          resize="none"
          show-word-limit
        >
        </el-input>
      </div>
    </div>
    <div class="b-b-c">
      <div class="btn" @click="reset">重置</div>
      <div class="btn cn" @click="save">保存</div>
      <div class="f-line"></div>
    </div>
    <div class="line-w media">
      <div class="ll">
        <div class="title-w">工程图片</div>
        <div class="img-list">
          <el-upload
            class="picture-card"
            :class="{ hiddenBtn: filelist.length >= 3 }"
            :multiple="true"
            :action="
              baseUrl +
              '/Intelligence_Building_API/WeatherForecast/SavePictureList?type=3'
            "
            :on-success="successfn"
            :file-list="filelist"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :limit="3"
            :before-remove="br"
          >
            <img
              src="@/assets/images/common/cicon09.png"
              alt=""
              class="up-icon"
            />
            <i>({{ filelist.length }}/3)</i>
          </el-upload>
        </div>
      </div>
      <div class="lr">
        <div class="title-w">
          工程视频<span class="ts01">(mp4最大200M)</span>
        </div>
        <div class="v-c">
          <el-upload
            v-loading="videoLoading"
            class="video-card"
            :action="
              baseUrl +
              '/Intelligence_Building_API/WeatherForecast/SavePictureList?type=2'
            "
            :show-file-list="false"
            :on-success="handleVideoSuccess"
            :before-upload="beforeUploadVideo"
            :on-progress="uploadVideoProcess"
            :disabled="video != ''"
          >
            <div class="video-wrap">
              <video
                v-if="video != '' && videoFlag == false"
                :src="baseUrl + video"
                class="avatar"
                controls="controls"
              >
                您的浏览器不支持视频播放
              </video>
              <!-- <video v-if="video !='' && videoFlag == false"  src="http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4" class="avatar" controls="controls">您的浏览器不支持视频播放</video> -->

              <div class="up-w" v-else-if="video == '' && videoFlag == false">
                <img src="@/assets/images/common/cicon09.png" />
                <div class="f-btn">选择本地视频文件</div>
              </div>
              <div v-if="video" class="image-wrap-del" @click.stop="delVideo()">
                &times;
              </div>
            </div>
          </el-upload>
        </div>
      </div>
    </div>
    <!-- logo -->
    <div class="line-w media">
      <div class="ll">
        <div class="title-w">项目logo图片</div>
        <div class="img-list">
          <el-upload
            class="picture-card"
            :class="{ hiddenBtn: filelogo.length >= 1 }"
            :multiple="true"
            :action="
              baseUrl +
              '/Intelligence_Building_API/WeatherForecast/SavePictureList?type=3'
            "
            :on-success="successfnlogo"
            :file-list="filelogo"
            list-type="picture-card"
            :on-preview="handlePictureCardPreviewlogo"
            :on-remove="handleRemovelogo"
            :limit="3"
            :before-remove="br"
          >
            <img
              src="@/assets/images/common/cicon09.png"
              alt=""
              class="up-icon"
            />
            <i>({{ filelogo.length }}/1)</i>
          </el-upload>
        </div>
      </div>
     
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, computed, provide } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
import {
  UpdateProjectLogo,
  AddProjectPicture,
  AddProjectVideo,
  UpdateProjectOverview,
  GetProjectOverview,
  GetProjectPictureList,
  GetProjectVideoList,
  DeleteProjectPicture,
  DelPictureList,
  DeleteProjectVideo,
  GetProjectLogo,
} from "@/js/indexApi.js";
import { ElMessageBox, ElMessage } from "element-plus";
import { baseUrl } from "@/js/util.js";
export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      baseUrl: baseUrl,
      filelist: [], // 工程图片列表
      filelogo:[],
      videoLoading: false,
      videoFlag: false,
      video: "",
      videoid: "",
      ptext: {
        // 工程概况数据
        Content: "",
        ID: "",
        ProjectId: "",
        UserId: "",
      },
    });
    const cdata = getStorage("bscdata");

    const getPO = () => {
      // 获取工程概述
      let datas = { ProjectID: cdata.pid };
      GetProjectOverview(datas).then((res) => {
        console.log(res);
        let { Code, Data, Message } = res.data;
        if (Code == 1 && Data) {
          console.log(Data);
          state.ptext = Data;
        } else {
          // ElMessage({
          //   showClose: true,
          //   message: Message,
          //   type: "error",
          // });
        }
      });
    };
    const getPP = () => {
      // 获取工程图片
      let datas = { ProjectID: cdata.pid };
      GetProjectPictureList(datas).then((res) => {
        console.log(res);
        let { Code, Data, Message } = res.data;
        if (Code == 1) {
          console.log(Data);
          if (Data && Data.length) {
            Data.forEach((v) => {
              let obj = {
                name: v.ID,
                url: baseUrl + v.ImageUrl,
                response: [v.ImageUrl],
              };
              state.filelist.push(obj);
            });
          }
        } else {
          // ElMessage({
          //   showClose: true,
          //   message: Message,
          //   type: "error",
          // });
        }
      });
    };
    
    const getlogo=()=>{
      let datas={
        ProjectID:cdata.pid
      }
      GetProjectLogo(datas).then((res)=>{
        let {Code,Data,Message}=res.data;
        if(Code==1){
          if(Data&&Data.length&&Data[0]&&Data[0].ProjectLogo!=null){
           Data.forEach((v)=>{
             let obj={
              name:v.ID,
              url:baseUrl+v.ProjectLogo,
              response:[v.ProjectLogo]  
             }
             state.filelogo.push(obj)

           })
          }
        }
      })
    };
    const delDoc = (docs) => {
      // 删除文件
      DelPictureList(docs).then((res) => {
        ElMessage({
          showClose: true,
          message: "删除成功",
          type: "success",
        });
        // let {Code,Message} = res.data;
        // if (Code == 1) {

        // } else {
        //   ElMessage({
        //     showClose: true,
        //     message: Message,
        //     type: "error",
        //   });
        // }
      });
    };
    const delDocP = (pdata) => {
      // 删除工程图片的路径
      console.log(pdata);
      let datas = { ID: pdata.ID };
      console.log(datas);
      DeleteProjectPicture(datas).then((res) => {
        console.log(res);
        let { Code, Message } = res.data;
        if (Code == 1) {
          delDoc([pdata.path]);
        } else {
          ElMessage({
            showClose: true,
            message: Message,
            type: "error",
          });
        }
      });
    };
    const delDocV = (pdata) => {
      // 删除工程视频的路径
      console.log(pdata);
      let datas = { ID: pdata.ID };
      DeleteProjectVideo(datas).then((res) => {
        console.log(res);
        let { Code, Message } = res.data;
        if (Code == 1) {
          state.videoLoading = false;
          state.videoFlag = false;
          state.video = "";
          state.videoid = "";
          delDoc([pdata.path]);
        } else {
          ElMessage({
            showClose: true,
            message: Message,
            type: "error",
          });
        }
      });
    };
    const getPV = () => {
      // 获取工程视频
      let datas = { ProjectID: cdata.pid };
      GetProjectVideoList(datas).then((res) => {
        console.log(res);
        let { Code, Data, Message } = res.data;
        if (Code == 1) {
          console.log(Data);
          if (Data && Data.length) {
            state.videoLoading = false;
            state.videoFlag = false;
            state.video = Data[0].VideoUrl;
            state.videoid = Data[0].ID;
          }
        } else {
          ElMessage({
            showClose: true,
            message: Message,
            type: "error",
          });
        }
      });
    };
    const initdata = () => {
      getPO();
      getPP();
      getPV();
      getlogo()
    };
    const methods = {
      br: (file) => {
        if (file.status === "success") {
          return ElMessageBox.confirm(
            "此操作将永久删除该文件, 是否继续?",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {})
            .catch((error) => {
              reject(false);
            });
        }
      },
      reset: () => {
        state.ptext.Content = "";
      },
      delVideo: () => {
        console.log(state.video);
        ElMessageBox.confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            delDocV({ ID: state.videoid, path: state.video });
            return true;
          })
          .catch(() => {
            return false;
          });
      },
      save: () => {
        // 保存数据
        const datas = {
          content: state.ptext.Content,
          userId: cdata.uid,
          projectId: cdata.pid,
        };
        if (state.ptext.ID) {
          datas.id = state.ptext.ID;
        }
        UpdateProjectOverview(datas).then((res) => {
          // 添加和修改工程概述
          let { Code, Message } = res.data;
          if (Code == 1) {
            ElMessage({
              showClose: true,
              message: Message,
              type: "success",
            });
            getPO();
          } else {
            ElMessage({
              showClose: true,
              message: Message,
              type: "error",
            });
          }
        });
      },
      // 上传图片
      successfn: (response, file, fileList) => {
        // 图片上传成功
        if (file.status === "success") {
          let datas = {
            imageUrl: response[0],
            userId: cdata.uid,
            projectId: cdata.pid,
          };
          AddProjectPicture(datas).then((res) => {
            // 添加工程图片
            let { Code, Data, Message } = res.data;
            if (Code == 1 && Data && Data.length) {
              fileList.forEach((v) => {
                if (v.uid == file.uid) {
                  v.name = Data[0];
                }
              });
              state.filelist = fileList;
              ElMessage({
                showClose: true,
                message: Message,
                type: "success",
              });
            } else {
              ElMessage({
                showClose: true,
                message: Message,
                type: "error",
              });
            }
          });
        }
      },
      handlePictureCardPreview: (file) => {
        // 图片预览 没写功能
        console.log(file, file.url);
        state.dialogImageUrl = file.url;
        state.dialogVisible = true;
      },
      handleRemove: (file, fileList) => {
        // 删除图片
        let path = file.response[0];
        let ID = file.name;
        state.filelist = fileList;
        delDocP({ ID: ID, path: path });
      },
      // 上传图片 logo
      successfnlogo: (response, file, fileList) => {
        // 图片上传成功
        if (file.status === "success") {
          let datas = {
            ProjectLogo: response[0],
    
            ID: cdata.pid,
          };
          UpdateProjectLogo(datas).then((res) => {
     

            let { Code, Data, Message } = res.data;
            if (Code == 1) {
              // fileList.forEach((v) => {
              //   if (v.uid == file.uid) {
              //     v.name = Data[0];
              //   }
              // });
              state.filelogo = fileList;
         
              ElMessage({
                showClose: true,
                message: Message,
                type: "success",
              });
            } else {
              ElMessage({
                showClose: true,
                message: Message,
                type: "error",
              });
            }
          });
        }
      },
      handlePictureCardPreviewlogo: (file) => {
        // 图片预览 没写功能
        console.log(file, file.url);
        state.dialogImageUrl = file.url;
        state.dialogVisible = true;
      },
      handleRemovelogo: (file, fileList) => {
        // 删除图片
        let path = file.response[0];
        let ID = file.name;
        state.filelogo = fileList;
         let datas = {
            ProjectLogo:null,
    
            ID: cdata.pid,
          };
          UpdateProjectLogo(datas).then((res) => {
            // 添加工程图片
        
            let { Code, Data, Message } = res.data;
            if (Code == 1) {
          
              ElMessage({
                showClose: true,
                message: Message,
                type: "success",
              });
            } else {
              ElMessage({
                showClose: true,
                message: Message,
                type: "error",
              });
            }
          });
        // delDocP({ ID: ID, path: path });
      },
      handleVideoSuccess(res, file, fileList) {
        // 获取视频上传图片地址
        state.videoFlag = false;
        if (file.status === "success") {
          const datas = {
            videoUrl: res[0],
            userId: cdata.uid,
            projectID: cdata.pid,
          };
          AddProjectVideo(datas).then((res02) => {
            // 添加工程视频
            let { Code, Data, Message } = res02.data;
            if (Code == 1 && Data && Data.length) {
              state.video = res[0];
              state.videoid = Data[0];
              state.videoLoading = false;
              ElMessage({
                showClose: true,
                message: Message,
                type: "success",
              });
            } else {
              ElMessage({
                showClose: true,
                message: Message,
                type: "error",
              });
            }
          });
        } else {
        }
      },
      // 视频处理
      beforeUploadVideo(file) {
        // 视频上传前校验
        const isLt10M = file.size / 1024 / 1024 < 200;
        if (
          [
            "video/mp4",
            "video/ogg",
            "video/flv",
            "video/avi",
            "video/wmv",
            "video/rmvb",
          ].indexOf(file.type) === -1
        ) {
          console.log("请上传正确的视频格式");
          return false;
        }
        if (!isLt10M) {
          ElMessage({
            showClose: true,
            message: "上传视频大小不能超过200MB哦!",
            type: "error",
          });
          console.log("上传视频大小不能超过200MB哦!");
          return false;
        }
      },
      uploadVideoProcess(event, file, fileList) {
        state.videoFlag = true;
        state.videoLoading = true;
      },
    };
    onMounted(() => {
      initdata();
    });
    // provide('popfalse',state.popfalse)
    return {
      ...toRefs(state),
      ...methods,
    };
  },
  components: {},
};
</script>
<style lang="scss" scoped>
#index {
  height: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 30px;
  .line-w {
    margin: 0 0 20px;
    &:deep(.el-textarea) {
      .el-textarea__inner {
        background: #f3f3f3;
        border-radius: 6px;
        border: 1px solid #dde1e6;
        width: 100%;
        margin: 15px 0 0;
        padding: 15px 20px;
        box-sizing: border-box;
        resize: none;
      }
      .el-input__count {
        background: #f3f3f3;
      }
    }
    &:deep(.img-list) {
      .el-upload-list--picture-card {
        .el-upload-list__item {
          width: 195px;
          height: 152px;
          border-radius: 0;
        }
        .el-upload-list__item-actions:hover span.el-upload-list__item-preview {
          display: none;
        }
      }
      .el-upload--picture-card {
        width: 195px;
        height: 152px;
        position: relative;
        background: #f3f3f3;
        border: 1px solid #999999;
        border-radius: 0;
        .up-icon {
          width: 57px;
          height: 57px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        i {
          position: absolute;
          right: 12px;
          bottom: 8px;
          font-size: 13px;
          color: #999999;
          display: inline-block;
          height: 20px;
          line-height: 20px;
        }
      }
      .hiddenBtn {
        .el-upload--picture-card {
          display: none;
        }
      }
    }
    &:deep(.v-c) {
      .video-card {
        width: 308px;
        height: 228px;
        background: #f3f3f3;
        border: 1px solid #999999;
        .el-upload--text {
          width: 100%;
          height: 100%;
        }
        .video-wrap {
          width: 100%;
          height: 100%;
          position: relative;
          .up-w {
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            img {
              width: 80px;
              height: 80px;
              margin: 50px auto 0;
            }
            .f-btn {
              display: inline-block;
              margin: 20px auto 0;
              padding: 0 15px;
              height: 32px;
              background: rgba(0, 180, 255, 0.1);
              border-radius: 4px;
              border: 1px solid #0083ff;
              width: 96px;
              font-size: 12px;
              color: #0083ff;
              line-height: 32px;
            }
          }
          video {
            width: 100%;
            height: 100%;
            object-fit: fill;
          }
        }
      }
    }
    &.media {
      display: flex;
      .title-w {
        margin: 0 0 16px;
      }
      .ll {
        width: 50%;
      }
      .lr {
        width: 50%;
      }
    }
  }
  .title-w {
    font-size: 14px;
    font-weight: bold;
    color: #666666;
    .ts01 {
      font-size: 13px;
      color: #999999;
      padding: 0 0 0 9px;
    }
  }
  .text-w {
    position: relative;
  }
  .b-b-c {
    margin: 20px 0 50px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    .btn {
      height: 36px;
      line-height: 36px;
      padding: 0 20px;
      border-radius: 5px;
      background: #b1b7c4;
      color: #fff;
      font-size: 14px;
      margin: 0 20px 0 0;
      cursor: pointer;
      &.cn {
        background: #0083ff;
      }
    }
    .f-line {
      position: absolute;
      height: 1px;
      background: #ddd;
      left: -30px;
      right: -30px;
      bottom: -20px;
    }
  }
  .image-wrap-del {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.6);
    line-height: 30px;
    color: #fff;
    font-size: 18px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}
</style>
